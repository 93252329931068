<!-- studentInfo -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />

    <GgPage pageType="2" title="Students">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>

      <GgPageHeader 
        :userInfo="userInfo"
        :title="`${userInfo.firstName||''} ${userInfo.lastName||''}`"
        :menuData="menuData"
        :subTitle1="userInfo.email"
        @_uploadCallbackLogo="_uploadCallbackLogo"
        @menuCallback="menuClick"
      >
        <div slot="title-label" class="title-label">
          <!-- @click="formDialogLocations.viewLocations = true"  -->
          <span @click="viewLocations = true">{{ checkedData[0] }}</span>
      <!--    <img
            v-if="checkedData.length > 1"
            class="icon-view"
            src="@/assets/images/icons/icon-view.png"
            alt=""
          /> -->
        </div>
        <!-- <div slot="avatarHeader" class="avatar">
          <head-img class="imgBox" :src="userInfo.avatar" line-height="52" />
        </div> -->
        <div slot="subTitleLabel" class="title-label">
          {{ userInfo.releationship }}
        </div>
        <div slot="info">
          <div class="title-status mar-b-0"
		  :class="{
		    red: userInfo.parentStatus != 'Connected'||studentInfo.status=='Withdrawn',
		  }"
		  v-if="userInfo.parentStatus"
		  >
            {{studentInfo.status=='Withdrawn'?'Disconnected':userInfo.parentStatus=='Connected'?'Connected':'Disconnected'}}
			<!-- {{ userInfo.parentStatus }} -->
          </div>
        </div>
      </GgPageHeader>
      <!--  section-info1 -->
      <section-info1
        v-loading="loading" 
        :data="profileList"
        :renderPass="is_changed"
        title="Profile"
        :iconEdit="studentStatus !== 'Withdrawn' && ($buttonPermission('Students', 'Full') ||
            $buttonPermission('Students', 'Edit'))"
        :readonly.sync="readonly"
        @submit="submitEdit"
        @cancel="handleCancel"
        @input="input"
        @readonly="changeReadonly"
      >
        <template slot="renderHtml" slot-scope="scope">
          <baseGGSelect
            v-if="scope.data.key === 'gender'"
            v-model="scope.data.value"
            :placeholder="scope.data.placeholder"
            :key="scope.data.key"
            :value="scope.data.value"
            :ref="scope.data.key"
            class="float"
          >
            <md-option
              v-for="item in scope.data.selectData"
              :key="item"
              :value="item"
            >
              {{ item }}
            </md-option>
          </baseGGSelect>
          <baseGGSelect
            v-else-if="scope.data.key === 'race'"
            v-model="scope.data.value"
            placeholder="Race"
            class="float"
          >
            <md-option
              v-for="(item, index) in raceList"
              :key="index"
              :value="item.value"
            >
              {{ item.value }}
            </md-option>
          </baseGGSelect>
          <baseGGSelect
            v-else-if="scope.data.key === 'nationality'"
            v-model="scope.data.value"
            :placeholder="scope.data.placeholder"
            class="float"
          >
            <md-option
              v-for="(item, index) in nationalityList"
              :key="index"
              :value="item.value"
            >
              {{ item.value }}
            </md-option>
          </baseGGSelect>
          <baseGGSelect
            v-else-if="scope.data.key === 'residentialStatus'"
            v-model="scope.data.value"
            :placeholder="scope.data.placeholder"
            class="float"
          >
            <md-option
              v-for="(item, index) in residentialStatusList"
              :key="index"
              :value="item.value"
            >
              {{ item.value }}
            </md-option>
          </baseGGSelect>
          <baseGGSelect
            v-else-if="scope.data.key === 'idType'"
            v-model="scope.data.value"
            placeholder="ID type"
            class="float"
          >
            <md-option
              v-for="(item, index) in IDTypeList"
              :key="index"
              :value="item.value"
            >
              {{ item.value }}
            </md-option>
          </baseGGSelect>
          <PhoneNumber
            v-else-if="scope.data.key === 'phoneNumber'"
            :value="scope.data.value"
            v-model="scope.data.phoneNumberVal"
            :ref="scope.data.key"
            :key="scope.data.key"
            class="float"
          ></PhoneNumber>
          <PhoneNumber
            v-else-if="scope.data.key === 'officeNumber'"
            :value="scope.data.value"
            v-model="scope.data.officeNumberVal"
            :ref="scope.data.key"
            :key="scope.data.key"
			 placeholder='Office number'
            class="float"
          ></PhoneNumber>
        </template>
      </section-info1>
      <!--  section-info2 -->
      <Section title="Home address" class="margin-0">
        <div class="section-content">
          <ul class="section-content-input" v-if="homeAddress.readonly">
            <li class="item" :class="{ edit: !homeAddress.readonly }">
              <div class="label">Address</div>
              <div class="value">
                <div
                  v-if="homeAddress.homeAddress">
                  <div>{{ homeAddress.homeAddress }}</div>
                </div>
                <div v-else>-</div>
                <div v-if="homeAddress.homeAddressLine2">
                  <div>{{ homeAddress.homeAddressLine2 }}</div>
                </div>
                <div v-else>-</div>
                <div v-if="homeAddress.postalCode">
                  <div>{{ homeAddress.postalCode }}</div>
                </div>
                <div v-else>-</div>
              </div>
              <div class="label"></div>
              <div class="value"></div>
            </li>
            <li class="item" :class="{ edit: !homeAddress.readonly }">
              <div class="label">Home number</div>
              <div class="value">
                <div v-if="homeAddress.phone.phoneNumber">
                  {{ homeAddress.phone.countryCode }}
                  {{ homeAddress.phone.phoneNumber }}
                </div>
                <div v-else>-</div>
              </div>
              <div class="label"></div>
              <div class="value"></div>
            </li>
          </ul>
          <div v-if="!homeAddress.readonly" class="input-content">
            <gg-input
              v-model="homeAddress.homeAddress"
              key="address"
              ref="address"
              placeholder="Address"
              class="float"
            />
            <gg-input
              v-model="homeAddress.homeAddressLine2"
              key="addressLine2"
              ref="addressLine2"
              placeholder="Address line 2"
              class="float"
            />
            <gg-input
              v-model="homeAddress.postalCode"
              key="postalCode"
              ref="postalCode"
              placeholder="Postal code"
              class="float"
            />
            <PhoneNumber
              v-model="homeAddress.phone"
              ref="homeNumber"
              class="float"
            ></PhoneNumber>
          </div>
          <i
            v-if="
              homeAddress.readonly &&
              pageType === '1' &&
              studentStatus !== 'Withdrawn'&& ($buttonPermission('Students', 'Full') ||
            $buttonPermission('Students', 'Edit'))
            "
            class="iconfont iconedit"
            @click="editHomeAddress"
          ></i>
          <div v-if="!homeAddress.readonly" class="text-right">
            <BaseButton @click="cancleHomeAddress">Cancel</BaseButton>
            <BaseButton
              @click="submitHomeAddress"
              type="primary"
              :disabled="!is_homeChanged"
              >Save</BaseButton
            >
          </div>
        </div>
      </Section>
    </GgPage>
    <!-- view locations -->
    <gg-Dialog
      :append-to-body="false"
      :visible.sync="formDialogLocations.viewLocations"
      :show-close="true"
      title="Locations"
    >
      <div class="dialog-content">
        <CheckboxGroup
          :checkTitle="formDialogLocations.checkTitle"
          :checkedData="formDialogLocations.checkedData"
          :checkboxList="formDialogLocations.checkboxList"
          @check="handleCheck"
        />
        {{ formDialogLocations.checkTitle }}
      </div>
    </gg-Dialog>
    <!-- resetpassword dialog -->
    <gg-Dialog
      :visible.sync="formDialogPwd.visible"
      :before-close="cancelResetPwd"
      width="580px"
      ref="resetPassword"
    >
      <div slot="title">
        Reset password for
        <span class="title-bold">{{ formDialogPwd.name }}</span>
      </div>
      <div>
        <ResetPassword
          @sendEmail="resetByEmail"
          :autoSend="formDialogPwd.autoSend"
          v-model="formDialogPwd.data"
          @input="resetVerify"
        ></ResetPassword>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="cancelResetPwd">Cancel</gg-cancel-button>
        <gg-submit-button
          :disabled="formDialogPwd.isDisabled"
          @click="handleResetPwd"
          >Reset</gg-submit-button
        >
      </span>
    </gg-Dialog>
    <!-- edit family -->
    <BaseFormDialog1
      v-model="familyDialog.visible"
	  v-if="familyDialog.visible"
      :title="familyDialog.title"
      :data="familyDialog.dataList"
      @submit="_updateParent"
      :renderPass="familyDialog.renderPass"
	  :submitBtnTxt="parentStatusData=='Connected'?'Save':'Invite'"
      textReminder="* indicates a required field"
    >
      <template slot="renderHtml" slot-scope="scope">
        <baseGGSelect
          v-if="scope.data.key === 'releationship'"
          v-model="scope.data.value"
          :placeholder="scope.data.placeholder"
          ref="releationship"
          class="float"
        >
          <md-option
            v-for="(item, index) in relationshipList"
            :key="index"
            :value="item.value"
          >
            {{ item.value }}
          </md-option>
        </baseGGSelect>
		<div  class="md-field gg_input md-theme-default md-has-value float spec" 
		style="line-height: 2;"	v-if="scope.data.key === 'email' && parentStatusData=='Connected'" >
	<label data-v-513b0464="" for="undefined" style="color: #b4b4b4;">Email *</label>
				<span style="white-space: nowrap;color: #202124;font-family: Roboto;font-size: 14px;" v-if="scope.data.key === 'email'"   class="float" :disabled='true'>
						{{ scope.data.value }}
				</span>
				<el-tooltip :visible-arrow="false" placement="bottom" effect="light" popper-class="text1"
				
					
					>
					 <div slot="content">Cannot edit as the contact has connected.
					Please<br/>ask him/her to update their email directly.</div>
					<div class="prompt">
						<img :src="require('@/assets/images/prompt.png')" />
					</div>
				</el-tooltip>
			</div>
		<gg-input
		  v-if="scope.data.key === 'email' && parentStatusData!='Connected' "
		  :ref="scope.data.key"
		  v-model="scope.data.value"
		  :placeholder="scope.data.placeholder"
		  :error="familyDialog.emailErrorObj"
		  class="float"
		/>
      </template>
	  <template slot="footer">
	    <baseButton @click="familyDialog.visible = false;">Cancel</baseButton>
	    <baseButton
	      type="primary"
	      :disabled="!familyDialog.renderPass"
	      @click="_updateParent"
	      >{{parentStatusData=='Connected'?'Save':'Invite'}}</baseButton
	    >
	  </template>
    </BaseFormDialog1>
	<!-- view locations -->
	<gg-Dialog :append-to-body="false" :visible.sync="viewLocations" :show-close="true"
		title="Locations">
		<div class="dialog-content">
			<CheckboxGroup :checkTitle="checkTitle"
				:checkedData="checkedData" :checkboxList="checkboxList"
				 />
		</div>
	</gg-Dialog>
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
		nowEmail:"",
		errorObj: {},
		parentStatusData:"",
      menuDatas: [
        {
          name: "Personal info",
          key: "parentPersonalInfo",
          menuIcon: "",
        },
      ],
      breadcrumbData: [
        {
          routeName: "students",
          name: "Students",
        },
        {
          routeName: "studentsInfo",
          name: "学生名",
        },
        { name: "家长名" },
      ],
      activeIndex: "parentPersonalInfo",
      userInfo: {
        avatar: require("@/assets/logo.png"),
        firstName: "",
        lastName: "",
        email: "",
      },
      menuData: [
        "Mark as primary",
        "Invite",
        "Reset Password",
        "Edit",
        "Delete",
      ],
      formDialogLocations: {
        viewLocations: false,
        checkTitle: "Raffles Kidz International",
        checkedData: [],
        checkboxList: [],
      },
      profileList: [
        {
          placeholder: "Gender",
          value: "",
          type: "select",
          key: "gender",
          selectData: ["Female", "male"],
          errorText: "required",
          renderHtml: true,
        },
        {
          placeholder: "Race",
          value: "",
          type: "select",
          key: "race",
          selectData: ["Female", "male"],
          errorText: "required",
          renderHtml: true,
        },
        {
          placeholder: "Nationality",
          value: "",
          type: "select",
          key: "nationality",
          selectData: ["Female", "male"],
          errorText: "required",
          renderHtml: true,
        },
        {
          placeholder: "Residential status",
          value: "",
          type: "select",
          key: "residentialStatus",
          errorText: "required",
          renderHtml: true,
        },

        {
          placeholder: "ID type",
          value: "",
          type: "select",
          key: "idType",
          float: true,
          errorText: "required",
          renderHtml: true,
        },
        {
          placeholder: "ID no.",
          value: "",
          type: "input",
          key: "idNo",
          float: true,
          errorText: "required",
        },
        {
          placeholder: "Phone number",
          value: "",
          phoneNumberVal: {
            countryCode: "+65",
            phoneNumber: "",
          },
          key: "phoneNumber",
          float: true,
          errorText: "required",
          renderHtml: true,
        },
        {
          placeholder: "Office number",
          value: "",
          officeNumberVal: {
            countryCode: "+65",
            phoneNumber: "",
          },
          key: "officeNumber",
          float: true,
          errorText: "required",
          renderHtml: true,
        },
      ],
      homeAddress: {
        readonly: true,
        homeAddress: "",
        homeAddressLine2: "",
        postalCode: "",
        homeNumber: "",
        phone: {
          countryCode: "+65",
          phoneNumber: "",
        },
      },
      // 0217cy  start 这个参数用来判断当前表单是否修改  在getData里重置为false watch里监听改为true 控制点击编辑按钮时 是否弹窗
      is_changed: false,
      is_homeChanged: false,
      firstData: [],
      firstHomeData: {},
      is_started: false, //此参数用来避免初始化数据监控多次判断
      // end
      renderPass: true,
      renderPass2: true,
      slotData: ["nickname", "aliasName"],
      isDisabled: true,
      readonly: true,
      readonly2: true,
      raceList: [],
      nationalityList: [],
      residentialStatusList: [],
      IDTypeList: [],
      primaryLanguageList: [],
      motherTongueList: [],
      relationshipList: [],
      snackbar: {
        isShow: false,
        content: "",
      },
      formDialogPwd: {
        visible: false,
        data: "",
        autoSend: true,
        name: "",
        id: "",
        isDisabled: false,
      },
      rowId: null, // 修改学生状态用到ID
      messageObj: {
        Delete: {
          title: "Delete family ?",
          desc:
            "Once the family member is deleted, you will not be able to recover the data. And the parent will lose access to the student's information and not be able to receive any updates from system.",
          cancelTxt: "Cancel",
          confirmTxt: "Delete",
          snackbarContent: "Family member has been deleted",
        },
        Invite: {
          title: "Invite family ?",
          desc:
            "This will send an email invitation to the family member in order to create an account and connect with the student.",
          cancelTxt: "Cancel",
          confirmTxt: "Send invite",
          snackbarContent: "Email invite has been sent.",
        },
        Remind: {
          title: "Remind family ?",
          desc:
            "This will resend an email invitation to the family member in order to create an account and connect with the student.",
          cancelTxt: "Cancel",
          confirmTxt: "Send remind",
          snackbarContent: "Email remind has been resent.",
        },
		Disconnect: {
		  title: "Disconnect family ?",
		  desc:
		    "Once the family member is disconnected, the parent will lose access to the student's information and not be able to receive any updates from system.",
		  cancelTxt: "Cancel",
		  confirmTxt: "Disconnect",
		  snackbarContent: "Family member has been disconnected.",
		},
      },
      className: sessionStorage.getItem("className"),
      locationName: sessionStorage.getItem("schoolName"),
      studentStatus: JSON.parse(sessionStorage.getItem("studentInfo")).status,
      familyDialog: {
        loading: false,
        visible: false,
        title: "Edit family",
		emailErrorObj: {
		  show: false,
		  message: "",
		},
        dataList: [
          {
            placeholder: "First name *",
            key: "firstName",
            value: "",
            autofocus: true,
            float: true,
            reg: /[\S\s]/,
            errorText: "First name required.",
          },
          {
            placeholder: "Last name *",
            key: "lastName",
            value: "",
            float: true,
            reg: /[\S\s]/,
            errorText: "Last name required.",
          },
          {
            placeholder: "Relationship *",
            key: "releationship",
            value: "",
            list: ["Mother", "Father", "Teacher"],
            renderHtml: true,
          },
          {
            placeholder: "Email*",
            key: "email",
            value: "",
            float: true,
            reg: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
            errorText: "Email is invalid",
			renderHtml:true
          },
        ],
        renderPass: false,
      },
      familySlotData: ["releationship"],
      currentData: {},
      is_HomePass: false,
	  checkTitle:sessionStorage.getItem('checkTitle'),
	  checkedData:JSON.parse(sessionStorage.getItem('checkedData')),
	  checkboxList:JSON.parse(sessionStorage.getItem('checkboxList')),
	  viewLocations:false,
	  studentInfo:JSON.parse(sessionStorage.getItem('studentInfo'))
    };
  },
  computed: {
    studentId() {
      return JSON.parse(sessionStorage.getItem("studentInfo")).studentId;
    },
    pageType() {
      return JSON.parse(sessionStorage.getItem("studentInfo")).type;
    },
    viewLabel() {
      let checkedDataLen = this.formDialogLocations.checkedData.length,
        checkboxListLen = this.formDialogLocations.checkboxList.length;
      if (checkedDataLen == 1) {
        return this.formDialogLocations.checkedData[0];
      } else if (checkedDataLen == checkboxListLen) {
        return "All  locations";
      } else {
        return checkedDataLen + "locations";
      }
    },
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
  },
  watch: {
    profileList: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          let telPass =
            this.$refs.phoneNumber &&
            !this.$refs.phoneNumber.isError.show &&
            !!this.$refs.phoneNumber.value.phoneNumber &&
            this.$refs.officeNumber &&
            !this.$refs.officeNumber.isError.show &&
            !!this.$refs.officeNumber.value.phoneNumber;
          this.renderPass = telPass;
          this.profileList.forEach((item, index) => {
            if (item.key == "phoneNumber") {
              item.value = `${item.phoneNumberVal.countryCode} ${item.phoneNumberVal.phoneNumber}`;
            } else if (item.key == "officeNumber") {
              item.value = `${
                item.officeNumberVal.phoneNumber &&
                item.officeNumberVal.countryCode
                  ? item.officeNumberVal.countryCode
                  : "+65"
              } ${
                item.officeNumberVal.phoneNumber &&
                item.officeNumberVal.countryCode
                  ? item.officeNumberVal.phoneNumber
                  : ""
              }`;
            }
            if (this.is_started) {
              if (this.firstData[index].value == item.value) {
              } else {
                this.is_changed = true;
                sessionStorage.setItem(
                  "isChanged",
                  JSON.stringify(this.is_changed || this.is_homeChanged)
                );
              }
            }
          });
          // console.log("changed", this.is_changed);
        });
      },
    },
    userInfo: {
      deep: true,
      handler() {
        this.$nextTick(() => {});
      },
    },
    homeAddress: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          res.homeNumber = `${res.phone.phoneNumber}`;
          res.homeCountryCode = `${res.phone.countryCode}`;
          let telPass =
            this.$refs.homeNumber &&
            !this.$refs.homeNumber.isError.show &&
            !!this.$refs.homeNumber.value.phoneNumber;
          this.is_HomePass = !telPass;
          if (
            this.homeAddress.homeAddress == this.firstHomeData.homeAddress &&
            this.homeAddress.homeAddressLine2 ==
              this.firstHomeData.homeAddressLine2 &&
            this.homeAddress.postalCode == this.firstHomeData.postalCode &&
            this.homeAddress.phone.countryCode ==
              this.firstHomeData.phone.countryCode &&
            this.homeAddress.phone.phoneNumber ==
              this.firstHomeData.phone.phoneNumber
          ) {
          } else {
            this.is_homeChanged = true;
            sessionStorage.setItem(
              "isChanged",
              JSON.stringify(this.is_changed || this.is_homeChanged)
            );
          }
          // console.log(this.is_homeChanged);
        });
      },
    },
    "familyDialog.dataList": {
      handler() {
        this.$nextTick(() => {
			// console.log(res)
			
			if(this.$refs.email&&this.$refs.email.value){
				let res = this.$refs.email.value
				let isPass = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(res);
				if (res.length === 0) {
				  this.familyDialog.emailErrorObj = {
				    show: true,
				    message: "Email required",
				  };
				} else if (!isPass) {
				  this.familyDialog.emailErrorObj = {
				    show: true,
				    message: "Email address is invalid",
				  };
				} else {
				  this.familyDialog.emailErrorObj = {
				    show: false,
				    message: "",
				  };
				}
			}
          let renderPass = this.familySlotData.every(
            (item) => !!this.$refs[item] && !!this.$refs[item].value
          );
		  if(this.parentStatusData=='Connected'){
		  	this.familyDialog.dataList.forEach((item,index)=>{
		  			if(item.key=='email'){
						item.renderHtml=true
					}	  
		  	})		
		  }	
		  
		  
          this.familyDialog.renderPass = renderPass;
        });
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.is_changed || this.is_homeChanged) {
      let messageObj = {
        title: "Save changes",
        desc:
          "You have some unsaved changes.Please save them before you continue.",
        cancelTxt: "",
        confirmTxt: "",
      };
      let callback = () => {
        // this.handleUpdateClass("delete");
      };
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        showCancelButton: false, // 不显示取消按钮
        showConfirmButton: false,
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      }).then(() => {
        this.handleSuspend(messageObj.snackbarContent);
      });
    } else {
      next();
    }
  },
  created() {
    this.breadcrumbData = this.breadcrumb.breadcrumbData;
    this._getData();
    // this.filterMenu();
    this._getSettingList("general", "race", "raceList");
    this._getSettingList("general", "nationality", "nationalityList");
    this._getSettingList(
      "general",
      "residentialStatus",
      "residentialStatusList"
    );
    this._getSettingList("student", "primaryLanguage", "primaryLanguageList");
    this._getSettingList("student", "motherTongue", "motherTongueList");
    this._getSettingList("student", "idType", "IDTypeList");
    this._getSettingList("student", "relationship", "relationshipList");
  },
  methods: {
    filterMenu() {
      let menuData = this.menuData;
      if (this.$buttonPermission("Students contacts", "Full")) {
        this.menuData = menuData;
      } else if (this.$buttonPermission("Students contacts", "Edit")) {
        this.menuData = this.menuData.filter(
          (item) => item == "Edit" || item == "Reset Password"
        );
      } else {
        this.menuData = [];
      }
    },
    _getData() {
      this.loading = true;
      Ajax.get("/usermanage/parent/selectParentById", {
        id: this.$route.query.id, //
      })
        .then((res) => {
          let {
            homeAddress,
            homeAddressLine2,
            postalCode,
            countryCode,
            phoneNumber,
            officeNumber,
            homeNumber,
            locations,
            officeCountryCode,
            homeCountryCode,
          } = res.data;
          this.loading = false;

          this.firstData = [];
          this.is_changed = false;
          this.is_homeChanged = false;
          this.homeAddress.readonly = true;
          this.readonly = true;
          this.currentData = res.data;
          let a;
          this.profileList.forEach((item) => {
            item.value = res.data[item.key];
            if (item.key == "phoneNumber") {
              item.value = `${countryCode ? countryCode : "+65"} ${
                phoneNumber ? phoneNumber : ""
              }`;
              item.phoneNumberVal = {
                countryCode: countryCode ? countryCode : "+65",
                phoneNumber: phoneNumber ? phoneNumber : "",
              };
            } else if (item.key == "officeNumber") {
              item.value = `${
                officeNumber && officeCountryCode ? officeCountryCode : "+65"
              } ${officeNumber && officeCountryCode ? officeNumber : ""}`;
              item.officeNumberVal = {
                countryCode: officeCountryCode ? officeCountryCode : "+65",
                phoneNumber: officeNumber ? officeNumber : "",
              };
            }
            a = {
              key: item.key,
              value: item.value,
            };
            this.firstData.push(a);
          });

          this.familyDialog.dataList.forEach((item) => {
            item.value = res.data[item.key] || "";
          });
          this.firstHomeData = {};
          this.firstHomeData.homeAddress = homeAddress;
          this.firstHomeData.homeAddressLine2 = homeAddressLine2;
          this.firstHomeData.postalCode = postalCode;
          this.firstHomeData.phone = {
            countryCode: homeCountryCode ? homeCountryCode : "+65",
            phoneNumber: homeNumber ? homeNumber : "",
          };
          this.homeAddress.homeAddress = homeAddress;
          this.homeAddress.homeAddressLine2 = homeAddressLine2;
          this.homeAddress.postalCode = postalCode;
          this.homeAddress.phone = {
            countryCode: homeCountryCode ? homeCountryCode : "+65",
            phoneNumber: homeNumber ? homeNumber : "",
          };
		  // console.log(res.data)
          this.userInfo = {
            avatar: res.data.photo,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            status: res.data.status,
            studentId: res.data.studentId,
            email: res.data.email,
            releationship: res.data.releationship,
            parentStatus: res.data.parentStatus,
			isMaster:res.data.isMaster
          };
          this.rowId = res.data.id;

          this.formDialogLocations.checkboxList = locations || [];
          this.formDialogLocations.checkedData = locations || [];
          sessionStorage.setItem(
            "isChanged",
            JSON.stringify(this.is_changed || this.is_homeChanged)
          );
          this.is_started = true;

          
          let parentStatusData =
            this.userInfo.parentStatus.charAt(0).toUpperCase() +
            this.userInfo.parentStatus.slice(1);
			this.parentStatusData=parentStatusData
          if (parentStatusData === "Connected") {
            this.menuData[1] = "Disconnect";
			this.menuData.forEach((item,index)=>{
				if(item=='Delete'){
					this.menuData[index]={label:'Delete',isDisabled:true}	
				}
			})
          } else if (parentStatusData == "Disconnected") {
			// this.menuData = ["Invite",'Delete'];
			  this.menuData[1] = "Invite";
			  this.menuData.forEach((item,index)=>{
			  	if(typeof item === 'object' && item.label=='Delete'){
			  		this.menuData[index]="Delete"	
			  	}
			  })
          } else {
            this.menuData[1] = parentStatusData;
          }
		  // ...三个点权限
		  let menuData = this.menuData;
		  if (this.$buttonPermission("Students contacts", "Full")) {
		    this.menuData = menuData;
		  } else if (this.$buttonPermission("Students contacts", "Edit")) {
		    this.menuData = this.menuData.filter(
		      (item) => item == "Edit" || item == "Reset Password"
		    );
		  } else {
		    this.menuData = [];
		  }
		  
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _getSettingList(dataLevel, dataType, datalistName) {
      let data = {
        userId: this.user.userId,
        dataLevel,
        dataType,
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/getList", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
          this[datalistName] = response || [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editHomeAddress() {
      if (!this.is_changed) {
        this.readonly = true;
        this.homeAddress.readonly = false;
      } else {
        let messageObj = {
          title: "Save changes",
          desc:
            "You have some unsaved changes.Please save them before you continue.",
          cancelTxt: "",
          confirmTxt: "",
        };
        let callback = () => {
          // this.handleUpdateClass("delete");
        };
        this.$confirm(messageObj.desc, messageObj.title, {
          cancelButtonText: messageObj.cancelTxt,
          confirmButtonText: messageObj.confirmTxt,
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showCancelButton: false, // 不显示取消按钮
          showConfirmButton: false,
          callback: (action) => {
            if (action == "confirm") {
              callback(action);
            }
          },
        }).then(() => {
          this.handleSuspend(messageObj.snackbarContent);
        });
      }
    },
    cancleHomeAddress() {
      this._getData();
    },
    submitHomeAddress() {
      let params = {
        homeAddress: this.homeAddress.homeAddress,
        homeAddressLine2: this.homeAddress.homeAddressLine2,
        postalCode: this.homeAddress.postalCode,
        homeCountryCode: this.homeAddress.phone.countryCode,
        homeNumber: this.homeAddress.phone.phoneNumber,
        id: this.$route.query.id,
      };
      this.loading = true;
      Ajax.post("/usermanage/parent/updateParent", params).then((res) => {
        if (res.code === "0000") {
          this.homeAddress.readonly = true;
          this.handleSuspend("Family member has been edited");
        } else {
			
          // this.handleSuspend(res.message);
        }
        this.loading = false;
        this._getData();
      });
    },
    _updateParent() {
      let formData = {
        gender: "",
        race: "",
        nationality: "",
        residentialStatus: "",
        idType: "",
        idNo: "",
        phoneNumber: "",
        countryCode: "",
        officeNumber: "",

        firstName: "",
        lastName: "",
        releationship: "",
        email: "",
        id: this.$route.query.id,
      };

      this.profileList.forEach((item) => {
        if (item.key == "phoneNumber") {
          formData["countryCode"] = item.phoneNumberVal.countryCode;
          formData["phoneNumber"] = item.phoneNumberVal.phoneNumber;
          item.value = item.phoneNumberVal.phoneNumber;
        } else if (item.key == "officeNumber") {
          formData["officeNumber"] = item.officeNumberVal.phoneNumber;
          formData["officeCountryCode"] = item.officeNumberVal.countryCode;
          item.value = item.officeNumberVal.phoneNumber;
        } else {
          formData[item.key] = item.value;
        }
      });
      this.familyDialog.dataList.forEach((item) => {
        formData[item.key] = item.value;
      });
	  let currentParentInfo = JSON.parse(sessionStorage.getItem('currentParentInfo'))
	  formData.schoolId = currentParentInfo.schoolId
	  formData.studentId = currentParentInfo.studentId
	  formData.companyId = currentParentInfo.companyId
	  // formData.releationship = currentParentInfo.releationship
      this.loading = true;
	  let  a = '/usermanage/parent/updateParent'
	  if(formData.email!=this.userInfo.email){
		  a = '/usermanage/parent/updateParentEmail'
		  // console.log('请求修改邮箱')
		  // return false
	  }
      Ajax.post(a, formData)
        .then((res) => {
			if (res.code=="9999") {
			  this.familyDialog.emailErrorObj = {
			    show: true,
			    // message: res.message,
			    message: "Email already exists",
			  }
			  return false
			}
          if (res.code !== "0000") {
			  
            this.handleSuspend(res.message);
          }
		 this.familyDialog.visible = false;
		if(this.parentStatusData!='Connected'){
			this.currentData.id = res.data
			this.handleInvite1(formData.email);
			this.handleSuspend("Contact info has been updated and new invitation has been sent");
		}else{
			this._getData();
			this.handleSuspend("Family member has been edited");
		}
		  
          this.loading = false;
         
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _updateStatus(action) {
      let formData = {
        userId: this.rowId,
        parentStatus: action,
      };
      this.loading = true;
      Ajax.post("/usermanage/parent/updateStatus", formData)
        .then((res) => {
          this.loading = false;
          if (res.code !== "0000") return;
          this.handleSuspend(`Parent has been ${action}`);
		   this._getData();
          if (action == "Delete") this.$router.back();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _deleteStatus(action) {
      let formData = {
        id: this.rowId,
        status: action,
        className: this.className,
        locationName: this.locationName,
      };
      this.loading = true;
      Ajax.post("/usermanage/parent/deleteById", formData)
        .then((res) => {
          if (res.code === "0000") {
            this.handleSuspend(`Parent has been ${action}`);
          } else {
            this.$router.push("/students");
            this.handleSuspend(res.message);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        showClose: false,
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      }).then(() => {
        this.handleSuspend(messageObj.snackbarContent);
      });
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
    input(res) {
      // console.log(res);
    },
    handleCheck(val) {
      this.formDialogLocations.checkedData = val;
    },
    handleCancel() {
      // this.readonly = true;
      this._getData();
    },
    submitEdit() {
      this.readonly = true;
      this._updateParent();
    },
    menuSelect(key) {
      this.activeIndex = key;
    },
    menuClick(action) {
      this.$emit("menuCallback", action);
      let callback = () => {
        this._updateStatus(action);
      };
      switch (action) {
		  case "Disconnect":
		  callback = () => {
		    this._updateStatus('Disconnected');
		  };
		  this.openMessageBox(this.messageObj[action], callback);
		  break;
		  
			case "Mark as primary":
          // Family member has been marked as primary
          // action = "Withdrawn";
          // this.openMessageBox(this.messageObj[action], callback);
          this.handleSetMaster();
          break;
        case "Invite":
          callback = () => {
            this.handleInvite();
          };
          this.openMessageBox(this.messageObj[action], callback);
          break;
        case "Remind":
          callback = () => {
            this.handleInvite();
          };
          this.openMessageBox(this.messageObj[action], callback);
          break;
        case "Reset Password":
          this.openDialogPwd();
          break;
        case "Delete":
          action = "Delete";
          callback = () => {
            this._updateStatus(action);
          };
          this.openMessageBox(this.messageObj[action], callback);
          break;
        case "Edit":
          this.openFamilyDialog();
          return;
        default:
          return;
      }
    },
    handleInvite() {
      // invite
      let parmas = {
        email: this.currentData.email,
        id: this.currentData.id,
      };
      Ajax.post("/usermanage/email/inviteSendEmail", parmas).then((res) => {
        this._getData();
      });
    },
	_getData1() {
	  this.loading = true;
	  // console.log(this.currentData.id)
	  // console.log(123123123123)
	  // if(!this.currentData.id){
		 //  this.currentData.id = this.$route.query.id
	  // }
	  Ajax.get("/usermanage/parent/selectParentById", {
	    id: this.currentData.id || this.$route.query.id, //
	  })
	    .then((res) => {
	      let {
	        homeAddress,
	        homeAddressLine2,
	        postalCode,
	        countryCode,
	        phoneNumber,
	        officeNumber,
	        homeNumber,
	        locations,
	        officeCountryCode,
	        homeCountryCode,
	      } = res.data;
	      this.loading = false;
	
	      this.firstData = [];
	      this.is_changed = false;
	      this.is_homeChanged = false;
	      this.homeAddress.readonly = true;
	      this.readonly = true;
	      this.currentData = res.data;
	      let a;
	      this.profileList.forEach((item) => {
	        item.value = res.data[item.key];
	        if (item.key == "phoneNumber") {
	          item.value = `${countryCode ? countryCode : "+65"} ${
	            phoneNumber ? phoneNumber : ""
	          }`;
	          item.phoneNumberVal = {
	            countryCode: countryCode ? countryCode : "+65",
	            phoneNumber: phoneNumber ? phoneNumber : "",
	          };
	        } else if (item.key == "officeNumber") {
	          item.value = `${
	            officeNumber && officeCountryCode ? officeCountryCode : "+65"
	          } ${officeNumber && officeCountryCode ? officeNumber : ""}`;
	          item.officeNumberVal = {
	            countryCode: officeCountryCode ? officeCountryCode : "+65",
	            phoneNumber: officeNumber ? officeNumber : "",
	          };
	        }
	        a = {
	          key: item.key,
	          value: item.value,
	        };
	        this.firstData.push(a);
	      });
	
	      this.familyDialog.dataList.forEach((item) => {
	        item.value = res.data[item.key] || "";
	      });
	      this.firstHomeData = {};
	      this.firstHomeData.homeAddress = homeAddress;
	      this.firstHomeData.homeAddressLine2 = homeAddressLine2;
	      this.firstHomeData.postalCode = postalCode;
	      this.firstHomeData.phone = {
	        countryCode: homeCountryCode ? homeCountryCode : "+65",
	        phoneNumber: homeNumber ? homeNumber : "",
	      };
	      this.homeAddress.homeAddress = homeAddress;
	      this.homeAddress.homeAddressLine2 = homeAddressLine2;
	      this.homeAddress.postalCode = postalCode;
	      this.homeAddress.phone = {
	        countryCode: homeCountryCode ? homeCountryCode : "+65",
	        phoneNumber: homeNumber ? homeNumber : "",
	      };
		  // console.log(res.data)
	      this.userInfo = {
	        avatar: res.data.photo,
	        firstName: res.data.firstName,
	        lastName: res.data.lastName,
	        status: res.data.status,
	        studentId: res.data.studentId,
	        email: res.data.email,
	        releationship: res.data.releationship,
	        parentStatus: res.data.parentStatus,
			isMaster:res.data.isMaster
	      };
	      this.rowId = res.data.id;
	
	      this.formDialogLocations.checkboxList = locations || [];
	      this.formDialogLocations.checkedData = locations || [];
	      sessionStorage.setItem(
	        "isChanged",
	        JSON.stringify(this.is_changed || this.is_homeChanged)
	      );
	      this.is_started = true;
	
	      
	      let parentStatusData =
	        this.userInfo.parentStatus.charAt(0).toUpperCase() +
	        this.userInfo.parentStatus.slice(1);
			this.parentStatusData=parentStatusData
	      if (parentStatusData === "Connected") {
	        this.menuData[1] = "Disconnect";
			this.menuData.forEach((item,index)=>{
				if(item=='Delete'){
					this.menuData[index]={label:'Delete',isDisabled:true}	
				}
			})
	      } else if (parentStatusData == "Disconnected") {
			// this.menuData = ["Invite",'Delete'];
			  this.menuData[1] = "Invite";
			  this.menuData.forEach((item,index)=>{
			  	if(typeof item === 'object' && item.label=='Delete'){
			  		this.menuData[index]="Delete"	
			  	}
			  })
	      } else {
	        this.menuData[1] = parentStatusData;
	      }
		  // ...三个点权限
		  let menuData = this.menuData;
		  if (this.$buttonPermission("Students contacts", "Full")) {
		    this.menuData = menuData;
		  } else if (this.$buttonPermission("Students contacts", "Edit")) {
		    this.menuData = this.menuData.filter(
		      (item) => item == "Edit" || item == "Reset Password"
		    );
		  } else {
		    this.menuData = [];
		  }
		  
	    })
	    .catch(() => {
	      this.loading = false;
	    });
	},
	handleInvite1(e) {
	  // invite
	  let parmas = {
	    email: e,
	    id: this.currentData.id,
	  };
	  Ajax.post("/usermanage/email/inviteSendEmail", parmas).then((res) => {
	    this._getData1();
	  });
	},
    handleRemind() {
     
	 
    },
    handleSetMaster() {
      let parmas = {
        userId: this.currentData.id,
      };
      Ajax.post("/usermanage/parent/setMaster", parmas).then((res) => {
        this._getData();
        this.handleSuspend("Family member has been marked as primary.");
      });
    },
    _uploadCallbackLogo(val) {
      this.userInfo.avatar = val;
      let params = {
        photo: val,
        id: this.$route.query.id,
      };
      Ajax.post("/usermanage/parent/updateParent", params).then((res) => {
        if (res.code === "0000") {
          this._getData();
          this.handleSuspend("Family member has been edited");
        }
      });
    },
    openDialogPwd() {
      this.formDialogPwd.name = `${this.userInfo.firstName} ${this.userInfo.lastName}`;
      this.formDialogPwd.id = this.userId;
      this.formDialogPwd.autoSend = true;
      this.formDialogPwd.isDisabled = false;
      this.formDialogPwd.visible = true;
    },
    cancelResetPwd() {
      this.formDialogPwd.visible = false;
      this.formDialogPwd.data = "";
    },
    handleResetPwd() {
      let isAuto = this.formDialogPwd.autoSend ? "1" : "0";
      this.formDialogPwd.visible = false;
      Ajax.post("/usermanage/parent/resetPasswdForParent", {
        userId: this.currentData.id,
        newPasswd: this.formDialogPwd.data,
        isAuto: isAuto,
      })
        .then((res) => {
          if (res.code === "0000") {
            this.handleSuspend("Password has been reset");
            this.formDialogPwd.data = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetByEmail(val) {
      this.formDialogPwd.autoSend = val;
      if (val || this.formDialogPwd.data.length > 8) {
        this.formDialogPwd.isDisabled = false;
      } else {
        this.formDialogPwd.isDisabled = true;
      }
    },
    //  start 0209cy 以防万一没有删除旧方法（resetByEmail） 如有需要可删除 此方法为验证密码是否超过8位
    resetVerify(val) {
      if (val.length > 7 || this.formDialogPwd.data.length > 7) {
        this.formDialogPwd.isDisabled = false;
      } else {
        this.formDialogPwd.isDisabled = true;
      }
    },
    // end
    openFamilyDialog() {
      this.familyDialog.visible = true;
    },
    changeReadonly() {
      if (!this.is_homeChanged) {
        this.readonly = false;
        this.homeAddress.readonly = true;
      } else {
        let messageObj = {
          title: "Save changes",
          desc:
            "You have some unsaved changes.Please save them before you continue.",
          cancelTxt: "",
          confirmTxt: "",
        };
        let callback = () => {
          // this.handleUpdateClass("delete");
        };
        this.$confirm(messageObj.desc, messageObj.title, {
          cancelButtonText: messageObj.cancelTxt,
          confirmButtonText: messageObj.confirmTxt,
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showCancelButton: false, // 不显示取消按钮
          showConfirmButton: false,
          callback: (action) => {
            if (action == "confirm") {
              callback(action);
            }
          },
        }).then(() => {
          this.handleSuspend(messageObj.snackbarContent);
        });
      }
    },
  },
};
</script>
<style type="text/css">
	.text1{
		padding:  15px!important;
		border: none!important;
		box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.1);
		border-radius: 8px!important;
		color: #202124!important;
		font-family: Roboto;
		font-size: 14px!important;
		/* top: 860rpx!important; */
		margin-top: 1px!important;
		
	}
</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .section-main {
  padding: 0;
}
/deep/ .el-dialog__body {
  max-height: 412px;
  overflow-y: auto;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 20px; */
  /deep/.float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
}
/deep/ .phone-number-content {
  padding-bottom: 0;
  padding-top: 20px;
}
/deep/.content {
  padding: 0;
}
/deep/.el-message-box__close .el-icon-close {
  display: none;
}
/deep/.md-field{
		    margin-bottom: 20px;
	}
	/deep/.section-content li.item{
		font-family: Roboto;
	}
	/deep/.section-content li.item .label{
		font-family: Roboto-medium;
	}
	/deep/.iconBox{
		display: none!important;
	}
	/deep/.avatar-uploader{
		cursor: default;
		 pointer-events: none;
	}
	.red{
		color: red;
	}
	.prompt{
		position:relative;
		left: 100px;
		bottom: 20px;
	}
/* 	.spec::before{
		display: none!important;
	} */
	.spec::after{
		display: none!important;
	}
	/deep/.menu_bar {
	  margin-top: 12px;
	  width: 40px;
	  height: 40px;
	  line-height: inherit;
	  .drop_down_menus {
	    padding: 0;
	  }
	  .iconmore_vert-24px{
		  font-size: 24px;
		  line-height: 40px;
	  }
	}
</style>
